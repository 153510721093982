import { createSelector, createSlice } from "@reduxjs/toolkit";
import { forumThunks } from "./forumThunkAction";

const initialState = {
    forumsData: {},
    forumActiveDiscussionData: {},
    status: 'idle',
    error: null
}

/* Forum Slice configuration */
const forumsSlice = createSlice({
    name: 'forumsData',
    initialState,
    reducers: {
        resetForumsAuthData: () => initialState,
        updateForumReactionData: (state, action) => {
            const { forumId, likesCount, likeEvent, liked, commentCount } = action.payload;
            const forumListData = state.forumsData?.body?.content;
            let index = forumListData?.findIndex(data => data?.id === forumId);
            if (index > -1) {
                state.forumsData.body.content[index].likesCount = likesCount;
                state.forumsData.body.content[index].likeEvent = likeEvent;
                state.forumsData.body.content[index].liked = liked;
                state.forumsData.body.content[index].commentCount = commentCount ? commentCount : state.forumsData.body.content[index].commentCount;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(forumThunks.fetchGlobalForumList.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(forumThunks.fetchGlobalForumList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForumsData = action.payload || {};
                state.forumsData = newForumsData;
            })
            .addCase(forumThunks.fetchGlobalForumList.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(forumThunks.fetchMoreGlobalForumList.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(forumThunks.fetchMoreGlobalForumList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForums = action.payload?.body;
                const newForumsData = action.payload?.body?.content || [];
                state.forumsData.body = { ...newForums, content: [...state.forumsData?.body?.content, ...newForumsData] };
            })
            .addCase(forumThunks.fetchMoreGlobalForumList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(forumThunks.fetchActiveDiscussionData.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(forumThunks.fetchActiveDiscussionData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForumsData = action.payload || {};
                state.forumActiveDiscussionData = newForumsData;
            })
            .addCase(forumThunks.fetchActiveDiscussionData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getGlobalForumListSelector = createSelector((state) => state?.forumsData, (forumsData) => forumsData);
export const getActiveDiscussionDataSelector = createSelector((state) => state?.forumActiveDiscussionData, (forumActiveDiscussionData) => forumActiveDiscussionData);
export const { resetForumsAuthData, updateForumReactionData } = forumsSlice.actions;
export default forumsSlice.reducer;