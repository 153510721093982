import { createSelector, createSlice } from "@reduxjs/toolkit";
import { approvalCommitteeThunks } from "./approvalCommitteeThunkAction";

const initialState = {
    approvalCommitteeData: {},
    status: 'idle',
    error: null
}

const approvalCommitteeSlice = createSlice({
    name: 'approvalCommittee',
    initialState,
    reducers: {
        resetApprovalCommitteeData: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(approvalCommitteeThunks.fetchApprovalCommitteeData.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(approvalCommitteeThunks.fetchApprovalCommitteeData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newApprovalCommitteeData = action.payload || {};
                state.approvalCommitteeData = newApprovalCommitteeData;
            })
            .addCase(approvalCommitteeThunks.fetchApprovalCommitteeData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getApprovalCommitteeSelector = createSelector((state) => state?.approvalCommittee, (approvalCommitteeData) => approvalCommitteeData);
export const { resetApprovalCommitteeData } = approvalCommitteeSlice.actions;
export default approvalCommitteeSlice.reducer;