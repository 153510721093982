import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
    communityUserData: {},
}

const communityUserSlice = createSlice({
    name: 'communityUser',
    initialState,
    reducers: {
        addCommunityUserData: (state, action) => {
            state.communityUserData = action.payload;
        },
        resetCommunityUserData: () => initialState,
    },
})

export const getCommunityUserSelector = createSelector((state) => state?.communityUserData, (communityUserData) => communityUserData);
export const { addCommunityUserData, resetCommunityUserData } = communityUserSlice.actions;
export default communityUserSlice.reducer;