import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";
import { auth_service } from "../../../authService";

const loginSuccess = auth_service.isAuthenticated();

const fetchGlobalCollabList = createAsyncThunk('collab/getGlobalForumList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCollabsList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch collab list');
    }
});

const fetchMoreGlobalCollabList = createAsyncThunk('collab/getMoreGlobalForumList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCollabsList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more collab list');
    }
});

const fetchCollabCommunityPost = createAsyncThunk('collabs/fetchPosts', async (data, { rejectWithValue }) => {
    try {
        data.loginSuccess = loginSuccess;
        let response = await axios.get(Apis.GetGlobalCommunityPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchMoreCollabCommunityPost = createAsyncThunk('collabs/fetchMorePosts', async (data, { rejectWithValue }) => {
    try {
        data.loginSuccess = loginSuccess;
        let response = await axios.get(Apis.GetGlobalCommunityPost(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more post');
    }
});


export const collabThunks = {
    fetchGlobalCollabList,
    fetchMoreGlobalCollabList,
    fetchCollabCommunityPost,
    fetchMoreCollabCommunityPost
}