import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";

const fetchApprovalCommitteeData = createAsyncThunk('approvalCommittee/fetchApprovalCommitteeData', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetApprovalCommitteeData(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

export const approvalCommitteeThunks = { 
    fetchApprovalCommitteeData
}