import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";

const fetchQuizQuesNews = createAsyncThunk('quizQues/fetch', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.post(Apis.SubmitSelectedQuiz, data);
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

export const quizQuesThunk = { fetchQuizQuesNews };

