import { createSelector, createSlice } from "@reduxjs/toolkit";
import { collabThunks } from "./collabThunkAction";

const initialState = {
    collabsData: {},
    collabsPostData: {},
    status: 'idle',
    error: null
}

/* Collab Slice configuration */
const collabSlice = createSlice({
    name: 'collabsData',
    initialState,
    reducers: {
        resetCollabsAuthData: () => initialState,
        resetCollabCommunityPostData: (state) => {
            state.collabsPostData = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(collabThunks.fetchGlobalCollabList.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(collabThunks.fetchGlobalCollabList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForumsData = action.payload || {};
                state.collabsData = newForumsData;
            })
            .addCase(collabThunks.fetchGlobalCollabList.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(collabThunks.fetchMoreGlobalCollabList.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(collabThunks.fetchMoreGlobalCollabList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForums = action.payload?.body;
                const newForumsData = action.payload?.body?.content || [];
                state.collabsData.body = { ...newForums, content: [...state.collabsData?.body?.content, ...newForumsData] };
            })
            .addCase(collabThunks.fetchMoreGlobalCollabList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(collabThunks.fetchCollabCommunityPost.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(collabThunks.fetchCollabCommunityPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPostsData = action.payload || {};
                state.collabsPostData = newPostsData;
            })
            .addCase(collabThunks.fetchCollabCommunityPost.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(collabThunks.fetchMoreCollabCommunityPost.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(collabThunks.fetchMoreCollabCommunityPost.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newPosts = action.payload?.body;
                const newPostsData = action.payload?.body?.content || [];
                state.collabsPostData.body = { ...newPosts, content: [...state.collabsPostData?.body?.content, ...newPostsData] };
            })
            .addCase(collabThunks.fetchMoreCollabCommunityPost.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
})

export const getGlobalCollabListSelector = createSelector((state) => state?.collabsData, (collabsData) => collabsData);
export const getCollabCommunityPostSelector = createSelector((state) => state?.collabsData, (collabsPostData) => collabsPostData);
export const { resetCollabsAuthData, resetCollabCommunityPostData } = collabSlice.actions;
export default collabSlice.reducer;