
import { Navigate } from 'react-router-dom';
import { auth_service } from '../../authService';
import { isWebView } from '../../../utils/Common';
import { constants } from '../../../utils/Constants';

export const PrivateRoute = ({ element, pathName }) => {
    if(isWebView()){
        return element;
    }
    if (!auth_service.isAuthenticated()) {
        if(pathName) {
            auth_service.setRedirectionUrl(pathName);
        }
        return <Navigate to="/login" />
    }
    return element;
};

export const PublicRoute = ({ element }) => {
    return element;
};

export const ProtectedRoute = ({ element }) => {
    if(isWebView()){
        return element;
    }
    const userDetails = auth_service.getUserDetails();
    const currentUserData = auth_service.getCurrentUserData();
    if (!auth_service.isAuthenticated()) {
        return <Navigate to="/login" />
    }
    if (((userDetails?.type === constants.institute || currentUserData?.type === constants.institute) && element?.props?.instituteAccess) || ((userDetails?.type === constants.candidate || currentUserData?.type === constants.candidate) && element?.props?.candidateAccess) || (userDetails?.type === constants.individual && !currentUserData?.type && element?.props?.individualAccess) || (userDetails?.type === constants.individual && ((currentUserData?.userType === constants.faculty && element?.props?.facultyAccess) || (currentUserData?.userType === constants.admin && element?.props?.adminAccess)))) {
        return element;
    }
    if(element?.props?.pageName === "MCQ_PRACTICE") {
        return <Navigate to={"/question-bank"} />
    }
    return <Navigate to={`/${userDetails?.type === constants.candidate ? "student" : (userDetails?.type === constants.institute || currentUserData?.userType === constants.institute) ? "institution" : "profile"}/${userDetails?.customUrl}`} />;
};

