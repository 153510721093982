import { GET_MCQ_DATA_ERROR, GET_MCQ_DATA_PENDING, GET_MCQ_DATA_SUCCESS, RESET_MCQ_DATA } from "../../actions/actionTypes";

const initialState = {
    status: 'idle', 
    mcqquestions: {},
    error: false,
  };

/* Reducer function for Mcq Question data */
export const mcqQuestionsReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_MCQ_DATA_PENDING:
            return {
                ...state,
                status: 'loading',
                pending: true,
            };

        case GET_MCQ_DATA_SUCCESS:
            const newMcqQuestionList = action.payload.mcqquestions;
            return {
                ...state,
                status: 'succeeded', 
                mcqquestions: newMcqQuestionList
            };
        case GET_MCQ_DATA_ERROR:  
            return {
                ...state,
                status: 'failed', 
                error: action.payload.error,
            };
        case RESET_MCQ_DATA:
            return initialState; 
        default:
            return state;
    }
};

/* Action creators for Quiz data*/
export const fetchMcqQuestionPending = () => ({
    type: GET_MCQ_DATA_PENDING
});

export const fetchMcqQuestionSuccess = (mcqquestions) => ({
    type: GET_MCQ_DATA_SUCCESS,
    payload: {
        mcqquestions: mcqquestions
    }
});

export const fetchMcqQuestionError = (error) => ({ 
    type: GET_MCQ_DATA_ERROR,
    payload: {
        error: error,
    },
});

export const resetMcqData = () => ({
    type: RESET_MCQ_DATA,
});

