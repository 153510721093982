import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";
import { auth_service } from "../../../authService";

const loginSuccess = auth_service.isAuthenticated();

const fetchGlobalForumList = createAsyncThunk('forum/getGlobalForumList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetGlobalForumList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch forum list');
    }
});

const fetchMoreGlobalForumList = createAsyncThunk('forum/getMoreGlobalForumList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetGlobalForumList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more forum list');
    }
});

const fetchActiveDiscussionData = createAsyncThunk('forum/fetchActiveDiscussionData', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetActiveDiscussionData(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch top community school');
    }
});


export const forumThunks = {
    fetchGlobalForumList,
    fetchMoreGlobalForumList,
    fetchActiveDiscussionData
}