import { DeviceUUID } from 'device-uuid';
import { v4 as uuidv4 } from 'uuid';
import { auth_service } from "../app/authService";
import addDays from 'date-fns/addDays';
import moment from "moment";
import { constants } from './Constants';
import * as FileSaver from "file-saver";

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const uppercaseTest = new RegExp("^(?=.*[A-Z])");
export const minCharacterTest = (minLength) => new RegExp(`^(?=.{${minLength}})`);
export const alphanumericRegex = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{10}$");
export const gstRegex = new RegExp('[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}');
export const dusnRegex = new RegExp("^(?=.{9})");
export const specialCharPattern = /[^a-zA-Z0-9]/;
export const digitPattern = /\d/;

const loginUserData = auth_service.getUserDetails();
const currentUserData = auth_service.getCurrentUserData();

export const getUserDataFromUrlParams = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const email = urlParams.get('email');
    const code = urlParams.get('code');
    const type = urlParams.get('type');
    const instituteId = urlParams.get('instituteId');
    const status = urlParams.get('status');
    const authToken = urlParams.get('authToken');
    const loginType = urlParams.get('loginType');
    const pageType = urlParams.get('pageType');
    const showQuizPage = urlParams.get('showQuizPage');
    const showTab = urlParams.get('showTab');
    const adoptionId = urlParams.get('adoptionId');
    let userParamsData = {
        email: email,
        code: code,
        userType: type,
        instituteId: instituteId,
        status: status,
        authToken: authToken,
        loginType: loginType,
        pageType: pageType,
        showQuizPage: showQuizPage,
        showTab: showTab,
        adoptionId: adoptionId
    }
    return userParamsData;
}

export const studentClassData = ["THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "TWELVEONWARDS", "NA"];

export const studentClassLevel = (value, flag) => {
    return (!flag && value !== "NA" ? "Class " : "") + (value === "THREE" ? "3" : value === "FOUR" ? "4" : value === "FIVE" ? "5" : value === "SIX" ? "6" :
        value === "SEVEN" ? "7" : value === "EIGHT" ? "8" : value === "NINE" ? "9" : value === "TEN" ? "10" :
            value === "ELEVEN" ? "11" : value === "TWELVE" ? "12" : value === "TWELVEONWARDS" ? "12 Onwards" : value);
}

export const studentClassObj = [{ "label": "Class 3", value: "THREE" }, { "label": "Class 4", value: "FOUR" }, { "label": "Class 5", value: "FIVE" }, { "label": "Class 6", value: "SIX" },
{ "label": "Class 7", value: "SEVEN" }, { "label": "Class 8", value: "EIGHT" }, { "label": "Class 9", value: "NINE" }, { "label": "Class 10", value: "TEN" }, { "label": "Class 11", value: "ELEVEN" },
{ "label": "Class 12", value: "TWELVE" }, { "label": "Class 12 Onwards", value: "TWELVEONWARDS" }]

export const getStudentClassData = (value, classList) => {
    let data = classList && classList.find((item) => item.name === value);
    return value === "ALL" ? "All" : data?.description || null
}

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const convertFirstCapital = (input) => {
    if (!input) {
        return input;
    }
    let output = input.replace(/%u(\w{4,4})/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
    output = output.replace(/\\u(\w{4,4})/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
    return output.charAt(0).toUpperCase() + output.slice(1);
}

export const dateFormatFunction = (date) => {
    let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
    function format(option) {
        let formatter = new Intl.DateTimeFormat('en', option);
        return formatter.format(date);
    }
    return options.map(format).join(" ");
}

export const epochTogmt = (date) => {
    let date1 = new Date(date);
    let day = date1.getDate();
    let month = date1.getMonth() + 1;
    let year = date1.getYear();
    return month > 12 ? 0 : month + "/" + day + "/" + year.toString().substr(-2);

}

export const messagePopUp = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
    })
};

export const postPopUpModalSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'View Post',
        cancelButtonText: "Later",
        allowOutsideClick: false,
    })
};

export const getAttachmentFileName = (url) => {
    var parsedUrl = url.substring(url?.lastIndexOf("/") + 1);
    let filename = parsedUrl?.substring(parsedUrl?.indexOf("-") + 1);
    return filename;
}

export const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

export const getCapitalizeFirstletter = (str) => {
    let capitalizedStr = str?.replace(/\b\w/g, char => char?.toUpperCase());
    return capitalizedStr;
};

export const capitalizeFirstLetter = (string) => {
    return string.length > 0 ? string[0].toUpperCase() + string.slice(1) : null;
}

export const getClassValue = (classLabel) => {
    if (classLabel === "Class 12 Onwards") {
        return "13";
    }
    return classLabel?.replace(/Class /g, "");
}

export const getUserTypeUrl = (type) => {
    return type === constants.institute ? "institution" : type === constants.candidate ? "student" : "profile";
}

export const minimumArticlesWarningModal = () => {
    return ({
        title: `You have already read minimum articles`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: 'Take a Quiz',
        cancelButtonText: 'Continue reading',
        allowOutsideClick: false,
    })
};

export const boardsNames = ["CISCE", "IB", "CBSE", "State Board"]

export const analyticsDateFilter = [
    { value: "TODAY", label: "TODAY" },
    { value: "YESTERDAY", label: "YESTERDAY" },
    { value: "LAST7DAYS", label: "LAST 7 DAYS" },
    { value: "LAST30DAYS", label: "LAST 30 DAYS" },
    { value: "LAST90DAYS", label: "LAST 90 DAYS" },
    { value: "LAST365DAYS", label: "LAST 365 DAYS" }
]

export const quizSummaryFilter = [
    { value: "TODAY", label: "TODAY" },
    { value: "LAST_DAY", label: "YESTERDAY" },
    { value: "LAST_7_DAYS", label: "LAST 7 DAYS" },
    { value: "LAST_1_MONTH", label: "LAST 30 DAYS" },
    { value: "LAST_90_DAYS", label: "LAST 90 DAYS" },
    { value: "LAST_365_DAYS", label: "LAST 365 DAYS" }
]

export const searchCategories = ["Class", "Name", "School ID"]

export const sectionOptions = [
    { value: 'NA', label: 'NA' },
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' }
];

export const confimSubscriptionModalSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Subscribe Now',
        cancelButtonText: 'Later',
        allowOutsideClick: false,
    })
};

export const confimStorySubmissionModalSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Continue Editing',
        allowOutsideClick: false,
    })
};

export const deviceId = () => {
    const Deviceuuid = new DeviceUUID().get() + '-' + uuidv4();
    if (!localStorage.getItem("deviceuuID")) {
        localStorage.setItem("deviceuuID", Deviceuuid);
    }
    return Deviceuuid;
};

export const confimSubmitModalSwal = (message, notShowCancelFlag) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: !notShowCancelFlag ? true : false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

export const confimPendingPostStatusSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
    })
};

export const getHours = (n) => {
    var num = parseInt(n);
    var hours = (num / 60);
    return Math.floor(hours);
}

export const getMinutues = (n) => {
    var num = parseInt(n);
    var hours = (num / 60);
    var minutes = (hours - Math.floor(hours)) * 60;
    return Math.round(minutes);
}

export const getHoursMinutesSeconds = (n, type) => {
    var number = Number(n);
    if (type === "HOUR") {
        return Math.floor(number / 3600);
    } else if (type === "MINUTUES") {
        return Math.floor(number % 3600 / 60);
    }
    return Math.floor(number % 3600 % 60);
}

export const numberFormatter = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e+9 ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B" :
        Math.abs(Number(labelValue)) >= 1.0e+6 ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M" :
            Math.abs(Number(labelValue)) >= 1.0e+3 ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K" : Math.abs(Number(labelValue));
}

export const sectionTitleMap = {
    LATEST: "Latest News",
    PUBLICATION: "Your Publication Mix",
    CATEGORY: "Your Categories",
    RECENT_READS: "Your Recent Reads",
    RECOMMEND_READS: "For You",
    SAVED: "Saved Items",
    default: ""
};

export const pageTitleMap = {
    LATEST: "Latest News",
    TRENDING: "Trending News",
    FOR_YOU: "For You",
    PUBLICATION: "All Publishers",
    CATEGORY: "All Categories",
    RECENT_READS: "Recent Reads",
    SAVED: "Saved Items",
    default: ""
};

export const pageIconClassMap = {
    LATEST: "iLatest",
    TRENDING: "iTrending",
    FOR_YOU: "iFor-You",
    PUBLICATION: "iPublisher",
    CATEGORY: "iCategory",
    RECENT_READS: "",
    default: ""
};

export const sectionPathMap = {
    LATEST: "/latest",
    PUBLICATION: "/publisher",
    CATEGORY: "/categories",
    RECENT_READS: "/recent-reads",
    RECOMMEND_READS: "/for-you",
    SAVED: "/saved-items",
    default: "javascript:void(0)"
};

export const isWebView = () => {
    var standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        if (!standalone && !safari) {
            return true
        }
        return false;
    } else {
        if (userAgent.includes('wv')) {
            return true
        }
        return false;
    };
}

export const closeModalBackDrop = () => {
    window.$("#reviewPopup").modal("hide");
    window.$('body').removeClass('modal-open');
    document.body.removeAttribute('style');
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
        backdrop.remove();
    }
}

export const publicationMessage = "The logos featured herein are proprietary trademarks belonging to their respective publication houses. Newsepick neither possesses nor asserts any ownership, control, or authorization over the Intellectual Property represented by these logos. Any reference to, or use of, these logos is made strictly for identification and informational purposes, without the intention of infringing upon the rightful ownership or Intellectual Property rights of the respective publication houses"
export const newsDeckMessage = "The news deck details provided here are comprehensive and independent of any applied filters. Represents a broad spectrum of global news, encompassing various topics and regions to ensure a holistic view of global events."


export const filterSelectionMap = {
    CATERGORY: "Category",
    PUBLISH_DATE: "Publish Date",
    PUBLICATION: "Publication",
    TRENDING: "Trending",
    VIEWS: "Views",
    default: ""
};
export const analyticsViewImpressionOptions = [
    { value: "VIEW", label: "View" },
    { value: "IMPRESSION", label: "Impression" },

]

export const getEpochTimeStamp = (days) => {
    return addDays(new Date().getTime(), -days)?.getTime();
}

export const publicationDateFilterOptions = [
    { value: "WITH_IN_24_HOURS", label: "With in 24 Hours", id: uuidv4() },
    { value: "7_DAYS", label: "7 Days", id: uuidv4() },
    { value: "30_DAYS", label: "30 Days", id: uuidv4() },
    { value: "90_DAYS", label: "90 Days", id: uuidv4() },
    { value: "THIS_YEAR", label: "This year", id: uuidv4() },
]

export const redirectToNewsepickWeb = (pageUrl, flag) => {
    let url = process.env.REACT_APP_newsePickFeURL;
    if (process.env.REACT_APP_environment === "prod") {
        url = "https://www.newsepick.com";
    }
    if (flag) {
        window.open(`${url}/${pageUrl}`, '_blank');
    } else {
        window.location.href = `${url}/${pageUrl}`;
    }
}

export const redirectToNewsepickStore = (pageUrl, flag) => {
    let url = process.env.REACT_APP_newsePickStoreURL;
    if (process.env.REACT_APP_environment === "prod") {
        url = "https://newsepick.store";
    }
    if (flag) {
        window.open(`${url}/${pageUrl}`, '_blank');
    } else {
        window.location.href = `${url}/${pageUrl}`;
    }
}

export const filterMessageMap = {
    CATERGORY: "You can select the category, and we will curate your latest and recommended feeds with relevant news in the selected category",
    PUBLISH_DATE: "You can select the news publish period and we will filter them in trending, your publication and recommended feeds for the selected period.",
    TRENDING: "You can filter the most trending news in any category, and we will curate it for your trending feeds for the selected category",
    PUBLICATION: "You can select your preferred publications, and we will curate latest and recommended feeds for the selected publications",
    VIEWS: "You can select to filter out the latest, recommended and your publication feeds according to their views stats on newsepick.",
    default: ""
};

export const checkInstituteProfile = () => {
    return loginUserData?.type === constants.institute || currentUserData?.type === constants.institute;
}

export const checkCandidateProfile = () => {
    return loginUserData?.type === constants.candidate || currentUserData?.type === constants.candidate;
}

export const getAllSectionAndSubject = (dataList, listType) => {
    let uniqueDataList = [...new Set(dataList?.flatMap(item => item[listType]))];
    uniqueDataList?.sort((a, b) => a?.toString().localeCompare(b?.toString()));
    return uniqueDataList;
}

const getUniqueSubjectList = (array, key) => [...new Map(array?.map(item => [item[key], item]))?.values()];

export const getAllSubjectList = (data) => {
    const allSubjects = data?.flatMap(item => item?.subjects);
    return getUniqueSubjectList(allSubjects, 'id');
};

export const userAssigmentOptions = [
    { value: "SCORE", label: "Score" },
    { value: "ATTEMPT", label: "Attempt" },
    { value: "RIGHT", label: "Right" },
    { value: "WRONG", label: "Wrong" },
    { value: "SKIPPED", label: "Skipped" }
]

export const instituteSectionConfiguration = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

export const reportAbuseOptions = ["Fake", "Inappropriate", "Others"]

export const reportPostOptions = [
    {label: "Inappropriate, abusive or offensive content", value: "INAPPROPRIATE_ABUSIVE_OR_OFFENSIVE_CONTENT" },
    {label: "Content promoting violence or terrorism", value: "CONTENT_PROMOTING_VIOLENCE_OR_TERRORISM" },
    {label: "Fake, spam or scam", value: "FAKE_SPAM_OR_SCAM" },
    {label: "Account may be hacked", value: "ACCOUNT_MAY_BE_HACKED" },
    {label: "Defamation, trademark or copyright violation", value: "DEFAMATION_TRADEMARK_OR_COPYRIGHT_VIOLATION" },
    {label: "Harassment or threat", value: "HARASSMENT_OR_THREAT" },
    {label: "Impersonating someone", value: "IMPERSONATING_SOMEONE" },
    {label: "Violates Terms Of Use", value: "VIOLATES_TERMS_OF_USE" },
    {label: "Others", value: "OTHERS" }
]

export const addConfirmPopUpForDownloadPdf = (type) => {
    return ({
        title: `Are you sure you want to download ${type || "invoice"}?`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

export const getUpdatedRecomendedData = (recommendedList, articleId) => {
    let updatedList = [...recommendedList];
    let index = updatedList && updatedList.findIndex(item => item?._id === articleId);
    if (index > -1) {
        updatedList?.splice(index, 1);
    }
    return updatedList;
};

export const getFacultyConfigurationFromHistory = (content) => {

    let uniqueSubjectsByClass = {};
    let sectionTypesByClass = {};
    content && content.forEach(item => {
        item?.classSectionSubjectList?.forEach(classItem => {
            const { studentClass, subjects, sectionType } = classItem;
            if (!uniqueSubjectsByClass[studentClass]) {
                uniqueSubjectsByClass[studentClass] = {};
            }
            subjects?.forEach(subject => {
                uniqueSubjectsByClass[studentClass][subject?.id] = subject;
                sectionTypesByClass[studentClass] = [];

            });
            if (!sectionTypesByClass[studentClass].includes(sectionType)) {
                sectionTypesByClass[studentClass].push(sectionType);
            }
        });
    });
    const result = Object.keys(uniqueSubjectsByClass)?.map(studentClass => ({ studentClass, subjects: Object.values(uniqueSubjectsByClass[studentClass]), sectionType: sectionTypesByClass[studentClass] && sectionTypesByClass[studentClass][0] }));
    return result;
}

export const trialSubscriptionConfirmPopUp = () => {
    return ({
        title: `Your trial period will start today.`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
    })
};

export const assignmentPermissionMessageMap = {
    faceVerficationMessage: "Please complete your face verification before attempting the assessment.",
    cameraBlockedMessage: "Please enable camera access in your browser settings to proceed.",
    cameraAccessMessage: "Camera access is required. Please grant permission to use the camera.",
    default: ""
};

export const assingmentUserOptions = [
    { value: constants.faculty, label: "Faculties" },
    { value: constants.candidate, label: "Students" },
]

export const getTimeInSeconds = (assignmentTime) => {
    return assignmentTime?.hours * 24 * 60 + assignmentTime?.minutes * 60 + assignmentTime?.seconds;
}

export const typeToKey = {
    "SCORE": "score",
    "ATTEMPT": "totalQuestionAttempted",
    "RIGHT": "totalRightAnswers",
    "WRONG": "totalWrongAnswers",
    "SKIPPED": "skippedAnswers"
};

export const getDuplicates = (arr, key) => {
    var filtered = arr.filter(function (el) { return !el });
    const keys = filtered.map(item => item[key]);
    return keys.filter(key => keys.indexOf(key) !== keys.lastIndexOf(key))
}

export const findCommonObjects = (arrays) => {
    if (arrays.length === 0) return [];
    return arrays[0].filter(item =>
      arrays.every(array =>
        array.some(otherItem => JSON.stringify(otherItem) === JSON.stringify(item))
      )
    );
}

export const playbackSpeeds = [
    { value: 0.5, label: '0.5x' },
    { value: 0.75, label: '0.75x' },
    { value: 1, label: '1x (Normal)' },
    { value: 1.5, label: '1.5x' },
    { value: 1.75, label: '1.75x' },
    { value: 2, label: '2x' },
];
  
export const isMobileDevice = () => {
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
    //ipad detection
    if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        isMobile = true;
    }
    return isMobile;
}

export const getGmtTimeFromEpoch = (time) => {
    return moment(time - 18300000).format("DD MMM, YYYY")
}

export const getCategoryIcon = (category) =>{
    if(category === "Business"){
        return "iBusiness"
    }
    else if(category === "Education"){
        return "iEducation"
    } 
    else if(category === "Entertainment"){
        return "iEntertainment"
    } 
    else if(category === "Environment"){
        return "iEnvironment"
    } 
    else if(category === "Health"){
        return "ihealth"
    } 
    else if(category === "Logistics"){
        return "iLogistics"
    } 
    else if(category === "Politics"){
        return "iPolitics"
    } 
    else if(category === "Research"){
        return "iResearch"
    } 
    else if(category === "Sports"){
        return "iSports"
    } 
    else if(category === "Technology"){
        return "iTechnology"
    } 
    else if(category === "Travel"){
        return "iTravel"
    } 
    return null
}

export const assemblyClass = [
    { label: "Class 3 - 5", value: "THREETOFIVE"},
    { label: "Class 6 - 8", value: "SIXTOEIGHT"},
    { label: "Class 9 - 12", value: "NINETOTWELVE"}
]

export const getAssemblyClassData = (value) => {
    let data = assemblyClass && assemblyClass.find((item) => item.value === value);
    return data?.label;
}

export const getOrderClassList = (mappedData) => {
    const classRankMap = {'THREE': 1,'FOUR': 2,'FIVE': 3, 'SIX': 4,'SEVEN': 5,'EIGHT': 6, 'NINE': 7,'TEN': 8,'ELEVEN': 9,'TWELVE': 10 };
    let result = mappedData?.sort((a, b) => classRankMap[a] - classRankMap[b]);
    return result;
};

export const frequencyOptions = [
    { label: "Daily", value : "DAILY" },
    { label: "Weekly", value : "WEEKLY" },
    { label: "Monthly", value : "MONTHLY" },
    { label: "Custom", value : "CUSTOM" }
]

export const quesBankConfigMap = {
    createQuestionBankMap: { true: "CREATE", false: "NONE"}, 
    globalVisibilityMap: { true: "GLOBAL", false: "PRIVATE"}
}

/* checking Mcq filters applied or not here */
export const hasMcqFiltersChanged = (filterState, currentFaculty) => {
    let defaultFaculty = "ALL";
    let classFilterState = filterState?.classes || [];
    let subjectFilterState = filterState?.subjects || [];
    let topicFilterState = filterState?.topics || [];
    let allFiltersData = [...classFilterState, ...subjectFilterState, ...topicFilterState];
    let isFilterChanged = allFiltersData?.some(filter => filter?.isSelected === false);
    let isFacultyChanged = currentFaculty !== defaultFaculty
    return isFilterChanged || isFacultyChanged;
};

export async function download_file(e, imageSrc) {
    e.preventDefault();
    const response = await fetch(imageSrc, { mode: 'no-cors', responseType: "blob" });
    const blobImage = await response.blob();
    const href = URL.createObjectURL(blobImage);
    var parsedUrl = imageSrc.substring(imageSrc.lastIndexOf("/") + 1);
    let filename = parsedUrl.substring(parsedUrl.indexOf('_') + 1)
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    FileSaver.saveAs(imageSrc, filename);
};

export const postCategoryList = ["ACADEMICS", "AWARDS", "AWARENESS", "CHALLENGES","CURRENT_AFFAIRS", "EVENTS", "FUN", "HISTORY", "INNOVATIONS",  "JUNIOR_REPORTERS", "STORIES", "STUDENT_SPOTLIGHTS", "TIPS_AND_TRICKS"];

export const communityCategoryList = [ "ENVIRONMENT", "SPORTS", "SOCIAL_CONCERNS", "BUSINESS", "TRAVEL", "ENTERTAINMENT", "POLITICS", "EDUCATION_AND_RESEARCH", "TECHNOLOGY_AND_INNOVATION", "CULTURAL_DIVERSITY", "HEALTH" ];

export const participantsOptions = [
    { value: 'GLOBAL', label: 'Global' },
    { value: 'INSTITUTE', label: 'Institute' },
    { value: 'SPECIFIC_GROUP', label: 'Specific Group' },
];

export const visibilityOptions = [
    { value: 'INSTITUTE', label: 'Institute' },
    { value: 'GLOBAL', label: 'Global' },
];

export const getPostCategoryIcon = (catergory) => {
    if(catergory === "STUDENT_SPOTLIGHTS") {
        return "iStudent-Spotlights";
    } else if (catergory === "JUNIOR_REPORTERS") {
        return "iJunior-Reporters";
    } else if (catergory === "CURRENT_AFFAIRS") {
        return "iCurrent-Affairs";
    } else if (catergory === "ACADEMICS") {
        return "iAcademics";
    } else if (catergory === "TIPS_AND_TRICKS") {
        return "iTips-and-Tricks";
    } else if (catergory === "INNOVATIONS") {
        return "iInnovations";
    } else if (catergory === "AWARDS") {
        return "iAwards";
    } else if (catergory === "CHALLENGES") {
        return "iChallenges";
    } else if (catergory === "AWARENESS") {
        return "iAwareness";
    } else if (catergory === "STORIES") {
        return "iStories";
    } else if (catergory === "EVENTS") {
        return "iEvents";
    } else if (catergory === "HISTORY") {
        return "iHistory";
    }
    return "iFun";
}

export const generateSlug = (data) => {
    if(data) {
        let value = data?.toLowerCase();
        value = value?.replace(/_/g, ' ');
        value = value?.split(' ').join('-');
        return value;
    }
    return null;
}

export const getTopicName = (topic) => {
    return topic?.replace(/-/g, '_')?.toUpperCase();
} 

export const getCommunityPageUrl = (type) => {
    return type === "COLLAB" || type === "COLLAB_DETAILS" ? "collab" : type === "FORUM" || type === "FORUM_DETAILS" ? "forum" : type !== "COMMUNITY_LIBRARY" ? "community" : "community-library";
}

export const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const getThumbnail = (data) => {
    if(data?.attachmentIds[0]?.attachmentType === "IMAGE" || data?.attachmentIds[0]?.attachmentType === "COVER_IMAGE") {
        return data?.attachmentIds[0]?.attachmentUrl;
    } else if (data?.attachmentIds[0]?.attachmentType === "PDF" && data?.attachmentIds[0]?.relatedImages?.length > 0) {
        return data?.attachmentIds[0]?.relatedImages[0];
    } else if (data?.attachmentIds[0]?.attachmentType === "VIDEO" && data?.thumbnail) {
        return data?.thumbnail;
    }
    return constants.defaultProductLogoMobile;
}

export const getFormattedDate = () => {
    const currentDate = new Date();

    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('en-US', { month: 'short' });
    const year = currentDate.getFullYear();

    return `${day}-${month}-${year}`;
};

export const getDefaultClassOptions = (classConfigData, studentClassDataList, userClass) => {
    let allOptions = classConfigData?.flatMap((item) => item["class"] || []);
    if( userClass && allOptions?.includes(userClass)){
        return { value: userClass, label: getStudentClassData(userClass, studentClassDataList) };
    }
    return { value: allOptions && allOptions[0], label: allOptions && getStudentClassData(allOptions[0], studentClassDataList) };
};

export const adddConfirmPopUp = (type, entity) => {
    return ({
        title: `Are you sure you want to ${type !== "edit" ? "publish" : "update"} this ${entity} post?`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};

export const viewModalSwal = (message) => {
    return ({
        title: `${message}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'View Now',
        cancelButtonText: "Later",
        allowOutsideClick: false,
    })
};

export const confimCommentModalSwal = (type) => {
    return ({
        title: `Are you sure you want to ${type === "COMMENT_UPDATE" ? "edit" : "delete"} this comment ?`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
    })
};
   