import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";

const fetchQuizData = createAsyncThunk('quizData/fetch', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetQuizDataUserwise(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

export const quizDataThunk = { fetchQuizData };

