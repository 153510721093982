import { createSelector, createSlice } from "@reduxjs/toolkit";
import { quizDataThunk } from "./QuizDataThunkAction";

const initialState = {
    status: 'idle', 
    pending: false,
    loadMorePending: false,
    quizlist: {},
    error: false,
};
  

const quizListSlice = createSlice({
    name: 'quizListData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(quizDataThunk.fetchQuizData.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(quizDataThunk.fetchQuizData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const quizlistData = action.payload || {};
                state.quizlist = quizlistData;

            })
            .addCase(quizDataThunk.fetchQuizData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getQuizDataSelector = createSelector((state) => state?.quizlist, (quizlist) => quizlist);
export default quizListSlice.reducer;