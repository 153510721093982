import { createSelector, createSlice } from "@reduxjs/toolkit";
import { quizQuesThunk } from "./QuizQuesThunkAction";

const initialState = {
    pending: false,
    loadMorePending: false,
    quizQuesList: {},
    error: false,
};
  

const quizQuesSlice = createSlice({
    name: 'quizQuesData',
    initialState,
    reducers: {
        resetQuizQuesData: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(quizQuesThunk.fetchQuizQuesNews.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(quizQuesThunk.fetchQuizQuesNews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const quizQuesData = action.payload || {};
                state.quizQuesList = quizQuesData;

            })
            .addCase(quizQuesThunk.fetchQuizQuesNews.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getQuizQuesSelector = createSelector((state) => state?.quizQuesList, (quizQuesList) => quizQuesList);
export const { resetQuizQuesData } = quizQuesSlice.actions;
export default quizQuesSlice.reducer;